import { FontFamily } from '~/local-fonts'

const getHostedFonts = async () => {
  const collection: FontFamily[] = [
    {
      name: 'Comic Neue',
      styles: [{
        family: 'Comic Neue',
        fullName: 'Comic Neue Bold',
        postscriptName: 'Comic-Neue-Bold',
        style: 'Bold'
      }]
    },
    {
      name: 'CC Wild Words',
      styles: [{
        family: 'CC Wild Words',
        fullName: 'CC Wild Words Bold',
        postscriptName: 'CC-Wild-Words',
        style: 'Regular'
      }]
    }
  ]
  return collection
}

export { getHostedFonts }