import { FontData } from '~/local-fonts'
import { getHostedFonts } from '~/services/fonts/hosted-fonts'
import { getLocalFonts } from '~/services/fonts/local-fonts'
import { TextStyles } from '~/types/editor/text-styles'

const getFontCollections = async () => {
  const localFonts = await getLocalFonts()
  const hostedFonts = await getHostedFonts()

  return [...hostedFonts, ...localFonts]
}

const fontVariantToObject = (variant: FontData) => ({
  family: variant.family,
  fullName: variant.fullName,
  postscriptName: variant.postscriptName,
  style: variant.style
})

const serializeFont = (textStyles: TextStyles) => {
  return {
    fontFamily: {
      name: textStyles.props.fontFamily.name,
      styles: textStyles.props.fontFamily.styles.map(entry => fontVariantToObject(entry))
    },
    fontVariant: fontVariantToObject(textStyles.props.fontVariant)
  }
}

export {
  getFontCollections,
  serializeFont
}