import { reportError } from '~/services/errors'
import { serializeFont } from '~/services/fonts/font-collection'
import { supabase } from '~/services/supabase'
import { CharacterTextStyles, TextStyles } from '~/types/editor/text-styles'
import { Database } from '~/types/supabase'

type SeriesId = Database['public']['Tables']['series']['Row']['id']
type TextStyleId = Database['public']['Tables']['text_styles']['Row']['id']

const getTextStyles = async (parent_series?: SeriesId) => {
  const query = parent_series ? (
    supabase
      .from('text_styles')
      .select('*')
      .eq('parent_series', parent_series)
  ) : (
    supabase
      .from('text_styles')
      .select('*')
  )
  const { data, error } = await query
  if (error) {
    reportError(error)
  }
  const textStyles = (data ?? []).map(entry => {
    const style: TextStyles = {
      meta: entry,
      ...JSON.parse(entry.definition)
    }
    return style
  })
  return textStyles
}

const serializeStyles = (textStyle: TextStyles, name: string | null) => {
  const serializedStyles: TextStyles = {
    ...textStyle,
    path: name,
    props: {
      ...textStyle.props,
      ...serializeFont(textStyle)
    }
  }
  return JSON.stringify(serializedStyles)
}

const serializeCharStyles = (charStyles: CharacterTextStyles) => {
  return JSON.stringify(charStyles)
}

const insertTextStyle = async (textStyle: TextStyles, name: string, parent_series?: SeriesId) => {  
  const row: Database['public']['Tables']['text_styles']['Insert'] = {
    name,
    definition: serializeStyles(textStyle, name),
    parent_series
  }
  
  const { error } = await supabase
    .from('text_styles')
    .insert([row])
  if (error) {
    reportError(error)
  }
}

const deleteTextStyle = async (textStyleId: TextStyleId) => {
  const { data, error } = await supabase
    .from('text_styles')
    .delete()
    .eq('id', textStyleId)
  if (error) {
    reportError(error)
  }
  return data
}

const updateTextStyle = async (textStyleId: TextStyleId, textStyle: TextStyles, name: string) => {
  const row: Database['public']['Tables']['text_styles']['Update'] = {
    id: textStyleId,
    name,
    definition: JSON.stringify(textStyle)
  }
  const { error } = await supabase
    .from('text_styles')
    .update(row)
  if (error) {
    reportError(error)
  }
}

export {
  getTextStyles,
  insertTextStyle,
  deleteTextStyle,
  updateTextStyle,
  serializeStyles,
  serializeCharStyles
}