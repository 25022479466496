import { FontFamily } from '~/local-fonts'

const getLocalFonts = async () => {
  const collection: FontFamily[] = []
  if (document.hasFocus() && 'queryLocalFonts' in window){
    const fonts = await window.queryLocalFonts()
    fonts.forEach(font => {
      const existingFamily = collection.find(family => family.name === font.family)
      if(existingFamily){
        existingFamily.styles.push(font)
      }else{
        collection.push({
          name: font.family,
          styles: [font]
        })
      }
    })
  }
  return collection
}

export { getLocalFonts }